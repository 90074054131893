// @flow strict
import { defineMessages } from "react-intl";

export default defineMessages({
  homeLink: {
    id: "HeaderNav.homeLink",
    defaultMessage: "Home",
    description: "Home nav title",
  },
  aboutLink: {
    id: "HeaderNav.aboutLink",
    defaultMessage: "About",
    description: "About nav title",
  },
  contactLink: {
    id: "HeaderNav.contactLink",
    defaultMessage: "Contact",
    description: "Contact nav title",
  },
  fleetLink: {
    id: "HeaderNav.fleetLink",
    defaultMessage: "Fleet",
    description: "Fleet nav title",
  },
  contactTitle: {
    id: "Contact.contactTitle",
    defaultMessage: "Contact",
    description: "Contact title",
  },
  addressTitle: {
    id: "Contact.addressTitle",
    defaultMessage: "ADDRESS",
    description: "Address title",
  },
  emailTitle: {
    id: "Contact.emailTitle",
    defaultMessage: "EMAIL",
    description: "Email title",
  },
  phoneTitle: {
    id: "Contact.phoneTitle",
    defaultMessage: "PHONE",
    description: "Phone title",
  },
  pricePerDay: {
    id: "Fleet.pricePerDay",
    defaultMessage: "Price per day",
    description: "Price per day",
  },
  minPrice: {
    id: "Fleet.minPrice",
    defaultMessage: "Min price",
    description: "Min price",
  },
  maxPrice: {
    id: "Fleet.maxPrice",
    defaultMessage: "Max price",
    description: "Max price",
  },
  clear: {
    id: "Fleet.clear",
    defaultMessage: "Clear",
    description: "Clear",
  },
  apply: {
    id: "Fleet.apply",
    defaultMessage: "Apply",
    description: "Apply",
  },
  carsNotFound: {
    id: "Fleet.carsNotFound",
    defaultMessage: "Cars not found",
    description: "Cars not found",
  },
  seats: {
    id: "Fleet.seats",
    defaultMessage: "{seats} seats",
    description: "Seats",
  },
  name: {
    id: "ContactForm.name",
    defaultMessage: "Name",
    description: "Name",
  },
  email: {
    id: "ContactForm.email",
    defaultMessage: "Email",
    description: "Email",
  },
  text: {
    id: "ContactForm.text",
    defaultMessage: "Text",
    description: "Text",
  },
  sendEmailButton: {
    id: "ContactForm.sendEmailButton",
    defaultMessage: "Send Email",
    description: "Send Email",
  },
  loading: {
    id: "ContactForm.loading",
    defaultMessage: "Loading...",
    description: "Loading...",
  },
  messageSended: {
    id: "ContactForm.messageSended",
    defaultMessage: "Email sent successfully!",
    description: "Message Sended",
  },
  errorWhileSending: {
    id: "ContactForm.errorWhileSending",
    defaultMessage: "Error sending email",
    description: "Error while sending",
  },
  noCarsFound: {
    id: "Fleet.noCarsFound",
    defaultMessage: "No cars found",
    description: "No cars found",
  },
  fleetType: {
    id: "Fleet.fleetType",
    defaultMessage: "Fleet type",
    description: "Fleet type",
  },
  carTypes: {
    id: "Fleet.carTypes",
    defaultMessage: "Car types",
    description: "Car types",
  },
  fuelTypes: {
    id: "Fleet.fuelTypes",
    defaultMessage: "Fuel types",
    description: "Fuel types",
  },
  filters: {
    id: "Fleet.filters",
    defaultMessage: "filters",
    description: "Filters",
  },
  gearbox: {
    id: "Fleet.gearbox",
    defaultMessage: "gearbox",
    description: "Gearbox",
  },
  price: {
    id: "Fleet.price",
    defaultMessage: "price",
    description: "Price",
  },
  filtersCount: {
    id: "Fleet.filtersCount",
    defaultMessage: "Filters {count}",
    description: "Filters Count",
  },
  Automatic: {
    id: "Fleet.Automatic",
    defaultMessage: "Automatic",
    description: "Automatic",
  },
  Manual: {
    id: "Fleet.Manual",
    defaultMessage: "Manual",
    description: "Manual",
  },
  gearboxTypes: {
    id: "Fleet.gearboxTypes",
    defaultMessage: "Gearbox Types",
    description: "Gearbox Types",
  },
  Petrol: {
    id: "Fleet.Petrol",
    defaultMessage: "Petrol",
    description: "Petrol",
  },
  Diesel: {
    id: "Fleet.Diesel",
    defaultMessage: "Diesel",
    description: "Diesel",
  },
  Electric: {
    id: "Fleet.Electric",
    defaultMessage: "Electric",
    description: "Electric",
  },
  Hybrid: {
    id: "Fleet.Hybrid",
    defaultMessage: "Hybrid",
    description: "Hybrid",
  },
  Pickup: {
    id: "Fleet.Pickup",
    defaultMessage: "Pickup",
    description: "Pickup",
  },
  SUV: {
    id: "Fleet.SUV",
    defaultMessage: "SUV",
    description: "SUV",
  },
  Hatchback: {
    id: "Fleet.Hatchback",
    defaultMessage: "Hatchback",
    description: "Hatchback",
  },
  Crossover: {
    id: "Fleet.Crossover",
    defaultMessage: "Crossover",
    description: "Crossover",
  },
  Sedan: {
    id: "Fleet.Sedan",
    defaultMessage: "Sedan",
    description: "Sedan",
  },
  Sport: {
    id: "Fleet.Sport",
    defaultMessage: "Sport",
    description: "Sport",
  },
  Coupe: {
    id: "Fleet.Coupe",
    defaultMessage: "Coupe",
    description: "Coupe",
  },
  Cabriolet: {
    id: "Fleet.Cabriolet",
    defaultMessage: "Cabriolet",
    description: "Cabriolet",
  },
});
