import { useMemo, useCallback } from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import LinkComponent from "components/Link";
import Text from "components/Text";
import { FormattedMessage } from "react-intl";
import { useRouter } from "next/router";
import useStore from "store";
import BurgerMenu from "./BurgerMenu";
import LanguageSelect from "./LanguageSelect";
import messages from "../../store/messages";
import { showLanguageSelect } from "../../constants";
import formatImage from "../../utils/formatImage";

type HeaderNavProps = {
  logo: string;
  widgetId: string;
};

const HeaderNav: React.FC<HeaderNavProps> = ({ logo, widgetId }) => {
  const { palette, other } = useTheme();
  const router = useRouter();
  const { locale } = useStore();
  
  const NavBody = styled.nav`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    height: 40px;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: 880px) {
      & img {
        height: 100%;
        width: auto;
      }
      & span {
        display: none;
      }
    }
  `;

  const MobileMenu = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    @media (min-width: 880px) {
      display: none;
    }
  `;

  const ImageBlock = styled.div`
    min-width: 100px;
    max-width: 230px;
    height: 100%;
    display: flex;
    align-items: center;
    & img {
      height: auto;
      object-fit: contain;
      max-height: 100%;
      width: 100%;
    }
    cursor: pointer;
  `;

  const Navigation = styled.ul`
    display: flex;
    gap: 10px;
    & a {
      transition: 0.2s ease all;
      padding: 10px 20px;
      border-radius: ${other.borderRadius};
      cursor: pointer;
      &:hover {
        background-color: ${palette.primary.dark};
      }
    }
    @media (max-width: 880px) {
      display: none;
    }
  `;

  const handleRedirectWithOldQuery = useCallback((link: string) => {
    if (router.pathname !== `/${link}`) {
      router.push(`/${link}?id=${widgetId}&locale=${locale}`);
    }
  }, [router, widgetId, locale]);

  const navLinks = useMemo(() => [
    { title: <FormattedMessage {...messages.homeLink} />, link: "" },
    { title: <FormattedMessage {...messages.aboutLink} />, link: "about-us" },
    { title: <FormattedMessage {...messages.fleetLink} />, link: "fleet" },
    { title: <FormattedMessage {...messages.contactLink} />, link: "contact" },
  ], []);

  return (
    <NavBody>
      <MobileMenu>
        {showLanguageSelect && <LanguageSelect />}
        <BurgerMenu navLinks={navLinks} />
      </MobileMenu>
      <ImageBlock
        role="button"
        tabIndex={0}
        onClick={() => handleRedirectWithOldQuery("")}
      >
        <img src={formatImage(logo, 300, 1000, "c_pad")} alt="header-logo" />
      </ImageBlock>

      <Navigation className="nc-Navigation lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
        {navLinks.map((item) => (
          <LinkComponent
            onClick={() => handleRedirectWithOldQuery(item.link)}
            key={item.link}
            className="hover:bg-gray-200 pointer transition ease-in rounded-3xl px-4 py-2"
          >
            <li>{item.title}</li>
          </LinkComponent>
        ))}
        {showLanguageSelect && <LanguageSelect />}
      </Navigation>

      <Text tag="span" variant="normal">
        Booking Engine
      </Text>

    </NavBody>
  );
};
export default HeaderNav;
