import { css } from "@emotion/react";
import palette from "theme/palette";
import "rc-slider/assets/index.css";
import "node_modules/@glidejs/glide/src/assets/sass/glide.core.scss";

/*
 * 1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
 */
export const preventPaddingAndBorder = css`
  *,
  &::before,
  &::after {
    box-sizing: border-box; /* 1 */
    border-width: 0; /* 2 */
    border-style: solid; /* 2 */
    border-color: theme("borderColor.DEFAULT", currentColor); /* 2 */
  }
`;

/*
 * 2. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
 */
export const allowAddingBorder = css`
  ::before,
  ::after {
    --tw-content: "";
  }
`;

/*
 * 1. Use a consistent sensible line-height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 * 3. Use a more readable tab size.
 * 4. Use the user's configured `sans` font-family by default.
 * 5. Use the user's configured `sans` font-feature-settings by default.
 */
export const htmlNormalizing = css`
  html {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    tab-size: 4;
    font-family: theme(
      "fontFamily.sans",
      ui-sans-serif,
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      Roboto,
      "Helvetica Neue",
      Arial,
      "Noto Sans",
      sans-serif,
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol",
      "Noto Color Emoji"
    );
    font-feature-settings: theme("fontFamily.sans[1].fontFeatureSettings", normal);
  }
`;

/*
 * 1. Remove the margin in all browsers.
 * 2. Inherit line-height from `html` so users can set them as a class directly on the `html` element.
 */
export const bodyNormalizing = css`
  body {
    margin: 0;
    line-height: inherit;
  }
`;

/*
1. Add the correct height in Firefox.
2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
3. Ensure horizontal rules are visible by default.
*/
export const normalizeHr = css`
  hr {
    height: 0;
    color: inherit;
    border-top-width: 1px;
  }
`;
/*
  Add the correct text decoration in Chrome, Edge, and Safari.
*/
export const normalizeTextDecoration = css`
  abbr:where([title]) {
    text-decoration: underline dotted;
  }
`;

/*
  Remove the default font size and weight for headings (and margin).
*/
export const normalizeDefaultFontStyle = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
    margin: 0;
  }
`;

/*
  Reset links to optimize for opt-in styling instead of opt-out.
*/
export const normalizeLinksStyle = css`
  a {
    color: inherit;
    text-decoration: none;
  }
`;

/*
 * Add the correct font weight in Edge and Safari.
 */
export const normalizeEdgeSafary = css`
  b,
  strong {
    font-weight: bolder;
  }
`;

/*
  1. Use the user's configured `mono` font family by default.
  2. Correct the odd `em` font sizing in all browsers.
*/
export const normalizeCodeKbdSampPre = css`
  code,
  kbd,
  samp,
  pre {
    font-family: theme(
      "fontFamily.mono",
      ui-monospace,
      SFMono-Regular,
      Menlo,
      Monaco,
      Consolas,
      "Liberation Mono",
      "Courier New",
      monospace
    ); /* 1 */
    font-size: 1em; /* 2 */
  }
`;

/*
 * 1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
 * 2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
 * 3. Remove gaps between table borders by default.
 */
export const normalizeTable = css`
  table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
  }
`;
/*
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 * 3. Remove default padding in all browsers.
 */
export const normalizeFieldset = css`
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
  }
`;

/*
  Remove the inheritance of text transform in Edge and Firefox.
*/
export const normalizeButtonSelect = css`
  button,
  select {
    text-transform: none;
  }
`;

/*
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Remove default button styles.
 */
export const normalizeButtons = css`
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none;
  }
`;

/*
 * Use the modern Firefox focus style for all focusable elements.
 */
export const normalizeFocus = css`
  :-moz-focusring {
    outline: auto;
  }
`;

/*
 * Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
 */
export const removeInvelid = css`
  :-moz-ui-invalid {
    box-shadow: none;
  }
`;

/*
 * Add the correct vertical alignment in Chrome and Firefox.
 */
export const normalizeVerticalAlign = css`
  progress {
    vertical-align: baseline;
  }
`;

/*
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
export const normalizeCursor = css`
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    height: auto;
  }
`;

/*
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
export const normalizeTypeSearch = css`
  [type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }
`;

/*
 * Remove the inner padding in Chrome and Safari on macOS.
 */
export const normalizeInnerPadding = css`
  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }
`;

/*
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
export const normalizeWebkitButtons = css`
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }
`;

/*
 * Add the correct display in Chrome and Safari.
 */
export const normalizeSummary = css`
  summary {
    display: list-item;
  }
`;

/*
 * Removes the default spacing and border for appropriate elements.
 */

export const normalizeTextSpecial = css`
  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: 0;
  }

  fieldset {
    margin: 0;
    padding: 0;
  }

  legend {
    padding: 0;
  }

  ol,
  ul,
  menu {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;

/*
 * Prevent resizing textareas horizontally by default.
 */

export const noramlizeTextArea = css`
  textarea {
    resize: vertical;
  }
`;

/*
 * 1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
 * 2. Set the default placeholder color to the user's configured gray 400 color.
 */
export const normalizePlaceholder = css`
  input::placeholder,
  textarea::placeholder {
    opacity: 1;
    color: theme("colors.gray.400", #9ca3af);
  }
`;

/*
 * Set the default cursor for buttons.
 */
export const normalizeButtonsCursors = css`
  button,
  [role="button"] {
    cursor: pointer;
  }
`;

/*
 * Make sure disabled buttons don't get the pointer cursor.
 */
export const normalizeDisabled = css`
  :disabled {
    cursor: default;
  }
`;

/*
 * 1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
 * 2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
 *    This can trigger a poorly considered lint error in some tools but is included by design.
 */
export const nomalizeMediaTags = css`
  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block;
    vertical-align: middle;
  }
`;

/*
 * Constrain images and videos to the parent width and preserve their intrinsic aspect ratio. (https://github.com/mozdevs/cssremedy/issues/14)
 */
export const normalizeImgVideo = css`
  img,
  video {
    max-width: 100%;
    height: auto;
  }
`;

/* Make elements with the HTML hidden attribute stay hidden by default */
export const normalizeHidden = css`
  [hidden] {
    display: none;
  }
`;

export const normalizeBody = css`
  html,
  body,
  #__next {
    min-height: 100vh;
    background-color: #ffffff;
    &.modal-open {
      overflow: hidden;
    }
  }
`;

const globalStyles = css`
  ${preventPaddingAndBorder}
  ${allowAddingBorder}
  ${htmlNormalizing}
  ${bodyNormalizing}
  ${normalizeButtonSelect}
  ${normalizeButtons}
  ${normalizeFocus}
  ${removeInvelid}
  ${normalizeVerticalAlign}
  ${normalizeCursor}
  ${normalizeTypeSearch}
  ${normalizeInnerPadding}
  ${normalizeWebkitButtons}
  ${normalizeSummary}
  ${normalizeLinksStyle}
  ${normalizeTextSpecial}
  ${noramlizeTextArea}
  ${normalizePlaceholder}
  ${normalizeButtonsCursors}
  ${normalizeDisabled}
  ${nomalizeMediaTags}
  ${normalizeImgVideo}
  ${normalizeHidden}
  ${normalizeBody}
`;

export default globalStyles;
