/* eslint-disable react/no-danger */
import { useMemo, useEffect } from "react";
import { Global, ThemeProvider } from "@emotion/react";
import Head from "next/head";
import Footer from "components/Footer";
import Header from "components/Header";
import type { AppProps } from "next/app";
import theme from "theme";
import useStore from "store";
import { createIntl, createIntlCache, RawIntlProvider } from "react-intl";
import formatImage from "utils/formatImage";
import globalStyles from "../styles";
import getMessages from "../utils/getMessages";

const cache = createIntlCache();

export default function App({ Component, pageProps }: AppProps) {
  const {
    seo, locale, widgetId, company,
    metaTags, scripts, currencySymbol,
  } = pageProps;

  const messages = useMemo(() => getMessages(locale), [locale]);
  
  const intl = createIntl(
    {
      locale,
      messages,
    },
    cache,
  );

  const { setLocale, setCurrencySymbol, setWidgetId } = useStore();
  
  useEffect(() => {
    setCurrencySymbol(currencySymbol);
    setLocale(locale);
    setWidgetId(widgetId);
  }, [locale, setCurrencySymbol, setLocale, widgetId, setWidgetId, currencySymbol]);

  const companyLogo = pageProps?.company?.logo;

  return (
    <RawIntlProvider value={intl}>
      <Head>
        {metaTags?.map((tag: { name: string, content: string }) => (
          <meta name={tag.name} content={tag.content} key={`${tag.name}${tag.content}`} />
        ))}
        {scripts?.map((script: { src: string, code: string, async: boolean, defer: boolean }) => (
          <>
            {script.src && (
              <script
                key={`${script.src}${script.code}`}
                src={script.src}
                async={script.async}
                defer={script.defer}
              />
            )}
            {script.code && (
              <script
                key={`${script.src}${script.code}`}
                dangerouslySetInnerHTML={{ __html: script.code }}
                async={script.async}
                defer={script.defer}
              />
            )}
          </>
        ))}
        <title>{seo?.title || "Home"}</title>
        <meta name="description" content={seo?.description || ""} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href={formatImage(company?.logo, 32, 32, "c_pad")} />
      </Head>
      <ThemeProvider theme={theme}>
        <Header
          logo={companyLogo || "/images/logo.png"}
          widgetId={pageProps.widgetId}
        />
        <Global styles={globalStyles} />
        <Component {...pageProps} />
        <Footer logo={companyLogo || "/images/logo.png"} />
      </ThemeProvider>
    </RawIntlProvider>
  );
}
