const palette = {
  primary: {
    main: "#ffffff",
    dark: "#ECECEC",
  },
  secondary: {
    light: "#f8fafc",
    main: "#f1f5f9",
  },
  additional: {
    light: "#f6ebff",
    main: "#7E22CE",
    dark: "#6F1BB7",
  },
  text: {
    light: "#64748b",
    main: "#0F172A",
    dark: "#1f2937",
  },
  border: {
    main: "#dceaf2",
    dark: "#5b5b5b",
  },
  linkColor: "#0169fe",
  backgroundColor: "#f5f5f5",

  primaryTextColor: "white",
  secondaryTextColor: "black",

  borderColor: "#dceaf2",
  borderSecondaryColor: "#5b5b5b",

  mainBlack: "#000000",
  mainBlue: "#0169FE",
  mainPrimary: "white",
  mainSecondary: "#f3f8fb",
};

export default palette;
