import { CSSProperties } from "react";

export const toKebabCase = (str: string) =>
  str
    .split(/(?=[A-Z])/)
    .join("-")
    .toLowerCase();

export const parseToCss = (obj: { string: string } | CSSProperties): string =>
  Object.entries(obj)
    .map(([key, value]) => `${toKebabCase(key)}: ${value};`)
    .join("\n");
