import { Theme } from "@emotion/react";
import text from "./text";
import palette from "./palette";
import other from "./other";
import sizes from "./sizes";

const theme: Theme = {
  spacing: 10,
  color: {
    primary: "#0169fe",
  },
  radii: {
    medium: "10px",
  },
  text: {
    ...text,
  },
  palette: {
    ...palette,
  },
  other: {
    ...other,
  },
  sizes: {
    ...sizes,
  },
};
export default theme;
