import { ReactNode, useCallback, useState } from "react";

import { useTheme } from "@emotion/react";

import styled from "@emotion/styled";
import BurgerNavList from "./NavList";

type BurgerMenuProps = {
  navLinks: { title: ReactNode; link: string }[];
};

const BurgerMenu: React.FC<BurgerMenuProps> = ({ navLinks }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { palette, other } = useTheme();

  const Burger = styled.div`
    display: flex;
    @media (min-width: 880px) {
      display: none;
    }
  `;
  const BurgerButton = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    background: ${palette.additional.main};
    height: 40px;
    width: 40px;
    transition: 0.3s ease all;
    border-radius: ${other.borderRadius};
    & div {
      width: 14px;
      height: 1px;
      background: #ffffff;
    }
    &.active {
      transform: rotate(-90deg);
    }
  `;
  
  const BurgerBody = styled.div`
    position: absolute;
    display: flex;
    top: 100%;
    z-index: -1;
    pointer-events: none;
    left: 0;
    width: 100%;
    border-radius: 10px;
    padding: 20px;
    background: ${palette.additional.main};
    transition: 0.3s ease all;
    opacity: 0;
    transform: translateY(-30px);
    &.active {
      z-index: 20;
      pointer-events: auto;
      transform: translateY(10px);
      opacity: 1;
    }
  `;

  const handleMenuOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <Burger>
      <BurgerButton onClick={handleMenuOpen} className={isOpen ? "active" : ""}>
        <div />
        <div />
        <div />
      </BurgerButton>
      <BurgerBody className={isOpen ? "active" : ""}>
        <BurgerNavList navLinks={navLinks} />
      </BurgerBody>
    </Burger>
  );
};
export default BurgerMenu;
