import styled from "@emotion/styled";
import { AnchorHTMLAttributes, ReactNode } from "react";
import { useTheme } from "@emotion/react";

import { parseToCss } from "../utils";

interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children: ReactNode;
  color?: string;
}

const LinkComponent = ({ children, color, ...props }: LinkProps) => {
  const { text } = useTheme();
  const themeStyles = text.link;
  const stylesString = parseToCss(themeStyles);
  const Link = styled.a`
    color: ${color};
    ${stylesString}
  `;

  return <Link {...props}>{children}</Link>;
};

export default LinkComponent;
