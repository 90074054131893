/* eslint-disable no-shadow */
import { create } from "zustand";

type Photo = {
  type: string
  link: string
  public_id: string
  name: string
}

type Step = {
  title: string
  text: string
  photo: Photo
}

type Benefit = {
  title: string
}

type LikeFounderBlock = {
  title:string
  text: string
  photo: Photo
}

export type BlockType = {
  type: string;
  photo?: Photo;
  title: string;
  text?: string
  titleTag: string;
  textTag: string;
  backgroundImage?: Photo;
  steps: Step[];
  newsBenefits?: Benefit[];
  likeFoundersBlocks?: LikeFounderBlock[];
}

export type Vehicle = {
  id:string;
  name: string;
  transmission: string;
  fuelType: string;
  pax: number;
  photos: Photo[];
  category: string;
  price: number;
}

export type Filters = {
  priceRangeFilter: string[];
  carTypesFilter: string[];
  fuelTypesFilter: string[];
  gearboxFilter: string[];
};

export type Company = {
  name: string;
  email: string;
  phone: string;
  address: string;
  website: string;
  logo: string;
}

type StoreType = {
  locale:string;
  filters: Filters;
  blocks: BlockType[];
  company: Company;
  vehicles: Vehicle[];
  currencySymbol: string;
  widgetId: string;
  domain: string;
  setVehicles: (vehicles: Vehicle[]) => void;
  setLocale:(locale:string)=>void;
  setBlocks: (blocks: BlockType[]) => void;
  setCompany: (company: Company) => void;
  setFilters: (filters: Filters) => void;
  setCurrencySymbol: (currencySymbol: string) => void;
  setWidgetId: (widgetId: string) => void;
  setDomain: (domain: string) => void;
};

const useStore = create<StoreType>((set) => ({
  widgetId: "",
  setWidgetId: (widgetId: string) => set(() => ({ widgetId })),
  currencySymbol: "€",
  setCurrencySymbol: (currencySymbol: string) => set(() => ({ currencySymbol })),
  locale: "",
  setLocale: (locale) => set(() => ({ locale })),
  vehicles: [],
  setVehicles: (vehicles) => set(() => ({ vehicles })),
  filters: {
    priceRangeFilter: [],
    carTypesFilter: [],
    fuelTypesFilter: [],
    gearboxFilter: [],
  },
  setFilters: (newFilters) => set(({ filters }) => ({ filters: { ...filters, ...newFilters } })),
  company: {
    name: "",
    email: "",
    phone: "",
    address: "",
    website: "",
    logo: "",
  },
  setCompany: (newCompany) => set(({ company }) => ({ company: { ...company, ...newCompany } })),
  blocks: [],
  setBlocks: (newBlocks) => set(({ blocks }) => ({ blocks: [...blocks, ...newBlocks] })),
  domain: "",
  setDomain: (domain) => set(() => ({ domain })),
}));

export default useStore;
