import { useCallback, useState } from "react";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import Text from "components/Text";
import useStore from "store";
import { useRouter } from "next/router";

const availableLanguages = [
  { flag: "\u{1F1FA}\u{1F1F8}", label: "EN", locale: "en" },
  { flag: "\u{1F1F7}\u{1F1FA}", label: "RU", locale: "ru" },
  { flag: "\u{1F1EE}\u{1F1F9}", label: "IT", locale: "it" },
  { flag: "\u{1F1E9}\u{1F1EA}", label: "DE", locale: "de" },
  { flag: "\u{1F1EB}\u{1F1F7}", label: "FR", locale: "fr" },
  { flag: "\u{1F1F5}\u{1F1F9}", label: "PT", locale: "pt" },
  { flag: "\u{1F1EA}\u{1F1F8}", label: "ES", locale: "es" },
  { flag: "\u{1F1F5}\u{1F1F1}", label: "PL", locale: "pl" },
  { flag: "\u{1F1E8}\u{1F1F3}", label: "CN", locale: "zh" },
];

function LanguageSelect() {
  const { locale, widgetId } = useStore();

  const theme = useTheme();
  const router = useRouter();

  const SelectContainer = styled.div`
    border-radius: ${theme.spacing}px;
    background-color: ${theme.palette.backgroundColor};
  `;

  const Languages = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 40px;
    background-color: white;
    border-radius: ${theme.spacing}px;
  `;

  const LanguageButton = styled.button`
    padding: ${theme.spacing / 2}px;
  `;

  const [selectOpen, setSelectOpen] = useState(false);

  const selectLanguage = useCallback((language) => {
    setSelectOpen(false);
    router.push(`${router.pathname}?id=${widgetId}&locale=${language.locale}`);
  }, [router, widgetId]);

  const selectedLanguage = availableLanguages.find((language) => language.locale === locale) || availableLanguages[0];

  const openSelect = useCallback(() => {
    setSelectOpen(!selectOpen);
  }, [selectOpen]);

  return (
    <SelectContainer>
      <LanguageButton onClick={openSelect}>
        <Text>
          {selectedLanguage.flag} {selectedLanguage.label}
        </Text>
      </LanguageButton>
      <Languages>
        {selectOpen && availableLanguages.map((language) => (
          <LanguageButton
            type="button"
            key={language.label}
            onClick={() => selectLanguage(language)}
          >
            <Text>
              {language.flag} {language.label}
            </Text>
          </LanguageButton>
        ))}
      </Languages>
    </SelectContainer>
  );
}

export default LanguageSelect;
