import palette from "./palette";

const textConfig = {
  h1: {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "48px",
    color: `${palette.text.main}`,
    lineHeight: "60px",
  },
  h1Big: {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "72px",
    color: `${palette.text.main}`,
    lineHeight: "83px",
  },
  h2: {
    fontFamily: "Poppins, sans-serif",
    fontSize: "36px",
    fontWeight: "600",
    lineHeight: "40px",
    color: `${palette.text.main}`,
    fontStyle: "normal",
  },
  normalExtraSmall: {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "500",
    color: `${palette.text.dark}`,
    fontSize: "12px",
    lineHeight: "16px",
  },
  normalSmall: {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    color: `${palette.text.light}`,
    fontSize: "14px",
    lineHeight: "20px",
  },
  normalSmallAdditional: {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    color: `${palette.additional.dark}`,
    fontSize: "14px",
    lineHeight: "20px",
  },
  normalSmallSecondary: {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    color: `${palette.primary.main}`,
    fontSize: "14px",
    lineHeight: "20px",
  },
  normal: {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    color: `${palette.text.light}`,
    fontSize: "16px",
    lineHeight: "24px",
  },
  normalSecondary: {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    color: `${palette.primary.main}`,
    fontSize: "16px",
    lineHeight: "24px",
  },
  normalAdditional: {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    color: `${palette.additional.dark}`,
    fontSize: "16px",
    lineHeight: "24px",
  },
  normalMedium: {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    color: `${palette.text.main}`,
    fontSize: "18px",
    lineHeight: "32px",
  },
  normalBig: {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    color: `${palette.text.light}`,
    fontSize: "20px",
    lineHeight: "28px",
  },
  normalBigSecondary: {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    color: `${palette.primary.main}`,
    fontSize: "20px",
    lineHeight: "28px",
  },
  normalExtraBig: {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    color: `${palette.text.light}`,
    fontSize: "24px",
    lineHeight: "28px",
  },
  boldExtraSmall: {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "500",
    color: `${palette.text.main}`,
    fontSize: "14px",
    lineHeight: "20px",
  },
  boldExtraSmall600: {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "600",
    color: `${palette.text.main}`,
    fontSize: "14px",
    lineHeight: "20px",
  },
  boldSmall: {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "500",
    color: `${palette.text.main}`,
    fontSize: "16px",
    lineHeight: "24px",
  },
  boldSmall600: {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "600",
    color: `${palette.text.main}`,
    fontSize: "16px",
    lineHeight: "24px",
  },
  boldSmallSecondary: {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "600",
    color: `${palette.primary.main}`,
    fontSize: "16px",
    lineHeight: "24px",
  },
  bold: {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "600",
    color: `${palette.text.main}`,
    fontSize: "20px",
    lineHeight: "28px",
  },
  boldSecondary: {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "600",
    color: "#ffffff",
    fontSize: "20px",
    lineHeight: "28px",
  },
  boldBig: {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "600",
    color: `${palette.text.main}`,
    fontSize: "24px",
    lineHeight: "32px",
  },
  boldExtraBig: {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "600",
    color: `${palette.text.main}`,
    fontSize: "30px",
    lineHeight: "36x",
  },
  link: {
    textDecoration: "none",
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
  },
  error: {
    fontFamily: "Poppins, sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    color: "red",
    fontSize: "14px",
    lineHeight: "20px",
  },
  body: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "Poppins, sans-serif",
  },
  body1: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "Poppins, sans-serif",
  },
};

export default textConfig;
