import React from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import textConfig from "theme/text";
import { parseToCss } from "../utils";

type TextProps = {
  variant: keyof typeof textConfig;
  tag?: "h1" | "h2" | "h3" | "span" | "p" | "div";
} & React.HTMLAttributes<HTMLHeadingElement | HTMLParagraphElement>;

const Text: React.FC<TextProps> = ({ variant = "normal", tag = "p", children, ...props }) => {
  const theme = useTheme();
  const tagThemeStyles = theme.text[variant];
  const stylesString = parseToCss(tagThemeStyles);

  const StyledTag = styled(tag)`
    vertical-align: middle;
    ${stylesString}
  `;

  return <StyledTag {...props}>{children}</StyledTag>;
};

export default Text;
