// @flow strict
type Crop =
  | "c_crop"
  | "c_scale"
  | "c_fit"
  | "c_limit"
  | "c_mfit"
  | "c_pad"
  | "c_lpad"
  | "c_mpad"
  | "c_fill"
  | "c_thumb"
  | "c_lfill"
  | "e_trim";

const formatImage = (imageUrl: string, width: number, height: number, crop: Crop): string => {
  try {
    if (!imageUrl) return imageUrl;
    if (typeof imageUrl !== "string") {
      throw new Error(`Error! Not correct image: ${imageUrl}`);
    }
    const matchUrl = imageUrl.match(/https:.+upload\//);
    
    if (matchUrl?.length) {
      const validQuality = ["best", "good", "ecco", "low"];
      const quality = "good";
      const uploadUrl = matchUrl[0];
      const fileName = imageUrl.substr(imageUrl.indexOf("upload") + 6);
      const widthOpt = width ? `w_${width}` : null;
      const heightOpt = height ? `h_${height}` : null;
      const urlQuality = quality && typeof quality === "number" ? `q_${quality}` : `q_auto${quality && validQuality.includes(quality) ? `:${quality}` : ""}`;
      const dimensions = [widthOpt, heightOpt, urlQuality, "f_auto", crop].filter(Boolean).join(",");
      return `${uploadUrl}${dimensions}${fileName}`;
    }

    return imageUrl;
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
  }
  return imageUrl;
};

export default formatImage;
