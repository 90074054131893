/* eslint-disable linebreak-style */
import messages from "../lang";

type Language = "de" | "en" | "es" | "fr" | "it" | "pl" | "pt" | "ru"

const getMessages = (locale: Language) => {
  let messagesWithDescriptions = locale ? messages[locale] : messages.en;
  if (!messagesWithDescriptions) {
    messagesWithDescriptions = messages.en;
  }

  return messagesWithDescriptions;
};

export default getMessages;
