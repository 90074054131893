import { ReactNode } from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import LinkComponent from "components/Link";

type BurgerNavListProps = {
  navLinks: { title: ReactNode; link: string }[];
};

const BurgerNavList: React.FC<BurgerNavListProps> = ({ navLinks }) => {
  const { palette } = useTheme();

  const NavUl = styled.ul`
    display: flex;
    width: 100%;
    flex-direction: column;
  `;
  const NavItem = styled.li`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px;
    text-transform: uppercase;
    font-size: 18px;
    &:active {
      background: ${palette.additional.dark};
    }
  `;
  return (
    <NavUl>
      {navLinks.map((item) => (
        <LinkComponent
          color="white"
          href={item.link}
          key={item.link}
        >
          <NavItem>{item.title}</NavItem>
        </LinkComponent>
      ))}
    </NavUl>
  );
};
export default BurgerNavList;
