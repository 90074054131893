import palette from "./palette";

const other = {
  borderRadius: "1.5rem",
  border: `1px solid ${palette.border.main}`,
  borderSecondary: `1px solid ${palette.border.dark}`,

  container: `
    margin-bottom: 6rem;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    @media (min-width: 640px) {
      max-width: 640px;
    }
    @media (min-width: 768px) {
      max-width: 768px;
    }
    @media (min-width: 1024px) {
      max-width: 1024px;
    }
    @media (min-width: 1280px) {
      max-width: 1280px;
    }
    @media (min-width: 1536px) {
      max-width: 1536px;
      padding-left: 128px;
      padding-right: 128px;
    }
  `,
};

export default other;
