import { useMemo, FC } from "react";
import { useTheme } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import LinkComponent from "./Link";
import Text from "./Text";
import messages from "../store/messages";
import formatImage from "../utils/formatImage";

export interface FooterProps {
  logo: string;
}

const Footer: FC<FooterProps> = ({ logo }) => {
  const { palette, other } = useTheme();
  
  const Body = styled.div`
    display: flex;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: 40px 0;
    width: 100%;
  `;
  
  const HeaderContainer = styled.div`
    width: 80vw;
    margin: 0 auto;
    display: flex;
    height:40px;
    justify-content: space-between;
    @media (max-width: 880px) {
      flex-direction: column;
      gap:20px;
      align-items:flex-start;
    }
    align-items: center;
  `;

  const ImageBlock = styled.div`
    min-width: 100px;
    height: 100%;
    display: flex;
    align-items: center;
    & img {
      height: 100%;
      width: auto;
    }
  `;

  const Navigation = styled.ul`
    display: flex;
    gap: 10px;
    & a {
      transition: 0.2s ease all;
      padding: 10px 20px;
      border-radius: ${other.borderRadius};
      &:hover {
        background-color: ${palette.primary.dark};
      }
    }
    @media (max-width: 880px) {
      flex-direction: column;
    }
  `;

  const navLinks = useMemo(() => [
    { title: <FormattedMessage {...messages.homeLink} />, link: "" },
    { title: <FormattedMessage {...messages.aboutLink} />, link: "/about-us" },
    { title: <FormattedMessage {...messages.fleetLink} />, link: "/fleet" },
    { title: <FormattedMessage {...messages.contactLink} />, link: "/contact" },
  ], []);

  return (
    <Body>
      <HeaderContainer>
        <ImageBlock>
          <img src={formatImage(logo, 350, 350, "c_pad")} alt="header-logo" />
        </ImageBlock>

        <Navigation className="nc-Navigation lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
          {navLinks.map((item) => (
            <LinkComponent
              href={item.link}
              key={item.link}
              className="hover:bg-gray-200 transition ease-in rounded-3xl px-4 py-2"
            >
              <li>{item.title}</li>
            </LinkComponent>
          ))}
        </Navigation>

        <Text tag="span" variant="normal">
          Booking Engine
        </Text>
      </HeaderContainer>
    </Body>
  );
};

export default Footer;
