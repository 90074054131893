import styled from "@emotion/styled";
import { FC } from "react";
import HeaderNav from "./HeaderNav/HeaderNav";

export interface HeaderProps {
  logo: string;
  widgetId: string;
}

const Header: FC<HeaderProps> = ({ logo, widgetId }) => {
  const Body = styled.div`
    display: flex;
    position: sticky;
    top: 0;
    padding: 20px 0;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    width: 100%;
    left: 0;
    z-index: 2;
    background-color: rgb(255 255 255 / 0.7);
    backdrop-filter: blur(40px);
    margin-bottom: 20px;
    @media (max-width: 880px) {
      padding: 10px 0;
    }
  `;

  const HeaderContainer = styled.div`
    width: 80vw;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `;

  return (
    <Body>
      <HeaderContainer>
        <HeaderNav
          logo={logo}
          widgetId={widgetId}
        />
      </HeaderContainer>
    </Body>
  );
};

export default Header;
